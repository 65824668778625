import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "../components/layout/GlobalStyles"
import { Seo } from "../components/Seo"

const StyledTermsOfUse = styled.section`
  background: ${colors.primary1};
  padding: 85.5px 0px 72px;
  a {
    color: ${colors.primary2};
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    margin: 0;
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 15px;
  }
  h2 {
    color: ${colors.primary2};
    margin-top: 32px;
  }
  h3 {
    margin-top: 32px;
    color: ${colors.dark3};
    margin-bottom: 5px;
  }
  h2,
  h3 {
    font-family: ${fonts.primary};

    font-weight: 900;
  }
  span,
  p {
    margin: 0px 0px 15px 0px;
    font-weight: 400;
    font-family: ${fonts.primary};
    color: ${colors.dark3};
  }
  @media ${device.laptop} {
    padding: 168px 0px 128px;
  }
`

const TermsOfUse = () => {
  return (
    <>
      <Seo
        title="MLIFE | Terms of Use"
        description="Our Terms of Use are repurposed from the nonprofit blog, Topnonprofits.com and made available under the Creative Commons License."
      />{" "}
      <StyledTermsOfUse>
        <div className="container">
          <h1>Terms of Use</h1>
          <p>
            Our Terms of Use are repurposed from the nonprofit blog,
            Topnonprofits.com and made available under the Creative Commons
            License. This means that you can tweak and edit them for your blog
            or website as long as you agree that they are provided to you for
            educational and informational purposes only and that they are not
            intended to substitute professional legal advice.
          </p>

          <p>
            Definition of Terms By continuing to our Site, you agree that the
            website, mlifefoundation.org is owned and controlled by MLIFE
            Foundation (We refer to ourselves from now on as
            &ldquo;MLIFE,&rdquo; or &ldquo;we&rdquo; or &ldquo;our&rdquo; or
            &ldquo;us&rdquo;). You also agree that our Terms of Use constitute a
            legally binding agreement made between you and us, whether
            you&rsquo;re acting for yourself or on behalf of an entity or
            entities (We refer to you as &ldquo;you&rdquo; or
            &ldquo;your&rdquo;). Our Terms of Use regulate how you access our
            website or any successor website or website(s), including any
            content, service, media, software, mobile website, and any mobile
            application linked or otherwise accessible in connection with our
            website (collectively, the &lsquo;Site&rsquo;). You are asked to
            read our Terms of Use carefully before accessing or using any part
            of our Site. By accessing or using any part of the Site, you agree
            to become bound without modification by our Terms of Use and all
            other terms and conditions contained herein including all applicable
            federal, state, and local laws and regulations, and all operating
            rules, policies, and procedures that may now or in future be
            published our Site (collectively, the &ldquo;Agreement&rdquo;).
          </p>

          <p>
            Change to our Terms of Use You grant us an irrevocable right, in our
            sole discretion, to make changes or modification to our Terms of Use
            at any time and for any reason and waive any right to receive
            specific notice of each such change. You agree that you&rsquo;ll be
            deemed to have accepted and made aware of any changes in our revised
            Terms of Use by your continued use of our Site after the date such
            revised Terms of Use are posted. You also agree that MLIFE may, in
            the future, offer new services and features through the Site
            (including, the release of new tools and resources) and such new
            features and services shall be subject to the terms and conditions
            of this Agreement. If you do not agree to all the terms and
            conditions of this agreement, then you may not access our Site or
            use any of our services. Your continued use of or access to our Site
            following the posting of any changes to this Agreement constitutes
            acceptance of those changes.
          </p>

          <p>
            Age verification By using our Site, you affirm that you are of legal
            age to enter into this agreement or if you are not, you are thirteen
            years of age or older and that you have obtained parental or
            guardian consent to enter into this agreement. If these terms and
            conditions are considered an offer by MLIFE, acceptance is expressly
            limited to these terms.
          </p>

          <p>
            Content Creation You agree that you are entirely responsible for the
            content of, and any harm resulting from that content, when you
            comment, post material, post links, or otherwise contribute to our
            Site either through hard copies submissions or electronic
            communications, or allow any third party or parties to make material
            available by means of our Site (&ldquo;Content&rdquo;). This is the
            case regardless of whether the Content in question constitutes text,
            graphics, an audio file, or computer software. By making Content
            available to us, you represent and warrant that:
          </p>

          <p>
            The downloading, copying and use of your Content will not infringe
            the proprietary rights, including but not limited to the copyright,
            patent, trademark or trade secret rights, of any third party; If
            your employer has rights to intellectual property you create, you
            have either (i) received permission from your employer to post or
            make available the Content, including but not limited to any
            software, or (ii) secured from your employer a waiver as to all
            rights in or to the Content; You have fully complied with any
            third-party licenses relating to your Content, and have done all
            things necessary to pass through to end users any required terms
            successfully; Your Content does not contain or install any viruses,
            worms, malware, Trojan horses or other harmful or destructive
            content; Your Content is not spam or machine- or randomly-generated,
            and does not contain unethical or unwanted commercial content
            designed to drive traffic to your site or third-party sites or boost
            your search engine rankings or those of third-party sites, or to
            further unlawful acts (such as phishing) or mislead recipients as to
            the source of the material (such as spoofing); Your Content is not
            pornographic, does not contain threats or incite violence towards
            individuals or entities, and does not violate the privacy or
            publicity rights of any third party; Your Content does not include
            foul or derogatory language whose intention is to cause harm or
            incite prejudice premised on falsehoods. Your blog is not getting
            advertised via unwanted electronic messages such as spam links on
            newsgroups, email lists, other blogs and web sites, and similar
            unsolicited promotional methods; Your blog is not named in a manner
            that misleads our readers into thinking that you are another person
            or company. For example, your blog&rsquo;s URL or name is not the
            name of a person other than yourself or company other than the one
            you own; You have, in the case of Content that includes computer
            code, accurately categorized and described the type, nature, uses,
            and effects of the materials, whether requested to do so by MLIFE or
            otherwise. By submitting your Content to MLIFE for inclusion on our
            Site, you automatically grant MLIFE a world-wide, royalty-free,
            unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
            transferable, and license to host, use, copy, reproduce, disclose,
            sell, resell, publish, broadcast, retitle, archive, store, cache,
            publicly perform, publicly display, reformat, translate, transmit,
            excerpt (in whole or in part), and distribute such Content
            (including, without limitation, your image and voice) for any
            purpose, commercial, advertising, or otherwise, and to prepare
            derivative works of, or incorporate into other tasks, such Content.
            You agree that distribution of your Content may occur in any media
            formats and through any media channels. Your license will apply to
            any form, media, or technology now known or hereafter developed, and
            includes our use of your name, company name, and franchise name, as
            applicable, and any of the trademarks, service marks, trade names,
            logos, and personal and commercial images you provide. You waive all
            moral rights in your Content, and you warrant that moral rights have
            not otherwise been asserted in your Content. You may request MLIFE
            to remove your Content from our Site for any reason. However, such
            request is at MLIFE&rsquo;s sole discretion, and If MLIFE agrees to
            remove such content, you acknowledge that caching or references to
            the Content may not be made immediately unavailable. Without
            limiting any of those representations or warranties, MLIFE has the
            right (though not the obligation) to, in MLIFE&rsquo; sole
            discretion (i) refuse or remove any content that, in MLIFE&rsquo;s
            reasonable opinion, violates any MLIFE&rsquo;s policy or is in any
            way harmful or objectionable, or (ii) terminate or deny access to
            and use of the Site to any individual or entity for any reason, in
            MLIFE&rsquo;s sole discretion. MLIFE will have no obligation to
            provide a refund of any amounts previously paid. Site Visitors MLIFE
            has not reviewed, and cannot review, all of the material, including
            computer software, posted to the Site, and cannot, therefore, be
            responsible for that material&rsquo;s content, use or effects. By
            operating the Site, MLIFE does not represent or imply that it
            endorses the material there posted, or that it believes such
            material to be accurate, useful or non-harmful. You are responsible
            for taking precautions as necessary to protect yourself and your
            computer systems from viruses, worms, Trojan horses, and other
            harmful or destructive content. The Site may contain content that is
            offensive, indecent, or otherwise objectionable, as well as content
            containing technical inaccuracies, typographical mistakes, and other
            errors. The Site may also contain material that violates the privacy
            or publicity rights or infringes the intellectual property and other
            proprietary rights, of third parties, or the downloading, copying or
            use of which is subject to additional terms and conditions, stated
            or unstated. MLIFE disclaims any responsibility for any harm
            resulting from the use by visitors of the Site, or from any
            downloading by those visitors of content there posted.
          </p>

          <p>
            Content Posted on Other Sites We have not reviewed, and cannot
            review, all of the material, including computer software, made
            available through the Sites and webpages to which
            mlifefoundation.org links, and that link to mlifefoundation.org.
            Links on the Website that lead to outside services and resources are
            provided for convenience only. We do not control the accuracy or
            availability of those services and resources and therefore not
            responsible for their contents or their use. By linking to a
            non-MLIFE Site or webpage, MLIFE does not represent or imply that it
            endorses such Site or webpage. You are responsible for taking
            precautions as necessary to protect yourself and your computer
            systems from viruses, worms, Trojan horses, and other harmful or
            destructive content. MLIFE disclaims any responsibility for any harm
            resulting from your use of non-MLIFE Sites and webpages. Any
            concerns regarding any such service or resource, or any link, should
            be directed to the particular service or support.
          </p>

          <p>
            Electronic Communication Most of our resources, services,
            questionnaires, and forms can be accessed and completed online. You
            may also select to send us an email and complete an online form on
            our Site or communicate to us or through a third-party site such as
            Facebook, Twitter, etc from accounts directly linked to our website
            and are independently managed by us (from now on referred to as
            &ldquo;electronic communications&rdquo;). Your use of electronic
            communications shall be deemed as consent by you to receive all
            agreements, notices, disclosures, and other communications via
            electronic communications and a waiver of any rights or requirements
            under any statutes, regulations, rules, ordinances, or other laws in
            any jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means. Electronic
            communications, including notices posted on our Site, shall satisfy
            any legal requirement that such notification is in writing. You
            agree to the use of electronic signatures, contracts, orders, and
            other records, and to electronic delivery of notices, policies, and
            records of transactions initiated or completed by us or via the
            Site.
          </p>

          <p>
            Governing Law Our Terms of Use and your use of our Site are governed
            by and construed by the laws of the State of California applicable
            to agreements made and to be entirely performed within the State of
            California.
          </p>

          <p>
            Intellectual Property This Agreement does not transfer from MLIFE to
            you any MLIFE or third-party intellectual property, and all right,
            title and interest in and to such property will remain (as between
            the parties) solely with MLIFE. MLIFE, mlifefoundation.org, and the
            MLIFE Foundation&rsquo;s logo, and all other trademarks, service
            marks, graphics and logos used in connection with the Site are
            trademarks or registered trademarks of MLIFE. Other trademarks,
            service marks, graphics, and logos used in connection with the Site
            may be the trademarks of other third parties. Your use of the Site
            grants you no right or license to reproduce or otherwise use any
            MLIFE or third-party trademarks.
          </p>

          <p>
            Copyright Infringement and DMCA Policy We respect the intellectual
            property rights of others. If you believe that material located on
            or linked to our Site violates your copyright, you are encouraged to
            notify MLIFE by MLIFE&rsquo;s Digital Millennium Copyright Act
            (&ldquo;DMCA&rdquo;) Policy. MLIFE will respond to all such notices,
            including as required or appropriate by removing the infringing
            material or disabling all links to the infringing material. MLIFE
            will terminate a visitor&rsquo;s access to and use of the Site if,
            under appropriate circumstances, the visitor is determined to be a
            repeat infringer of the copyrights or other intellectual property
            rights of MLIFE or others. In the case of such termination, MLIFE
            will have no obligation to provide a refund of any amounts
            previously paid to MLIFE.
          </p>

          <p>
            All communications regarding DMCA policy should be addressed as
            follows: Designated Copyright Agent MLIFE Foundation Attn: Copyright
            Agent 1909 Mission Street, Suite 105 San Francisco, CA 94103
            copyright@mlifefoundation.org
          </p>

          <p>
            Disclaimer of Warranties Our Site is provided &ldquo;as is&rdquo;.
            MLIFE and its suppliers and licensors hereby disclaim all warranties
            of any kind, express or implied, including, without limitation, the
            warranties of merchantability, fitness for a particular purpose and
            non-infringement. Neither MLIFE nor its suppliers and licensors,
            makes any warranty that the Site will be error free or that access
            thereto will be continuous or uninterrupted. You understand that you
            download from, or otherwise obtain content or services through, our
            Site at your own discretion and risk.
          </p>

          <p>
            Limitation of Liability In no event will MLIFE, or its suppliers or
            licensors, be liable with respect to any subject matter of this
            agreement under any contract, negligence, strict liability or other
            legal or equitable theory for: (i) any special, incidental or
            consequential damages; (ii) the cost of procurement for substitute
            products or services; (iii) for interruption of use or loss or
            corruption of data; or (iv) for any amounts that exceed the fees
            paid by you to MLIFE under this agreement during the twelve (12)
            month period prior to the cause of action. MLIFE shall have no
            liability for any failure or delay due to matters beyond their
            reasonable control. The foregoing shall not apply to the extent
            prohibited by applicable law.
          </p>

          <p>
            General Representation and Warranty You represent and warrant that
            (i) your use of our Site will be in strict accordance with the
            MLIFE&rsquo; Privacy Policy, with this Agreement and with all
            applicable laws and regulations (including without limitation any
            local laws or regulations in your country, state, city, or other
            governmental area, regarding online conduct and acceptable content,
            and including all applicable laws regarding the transmission of
            technical data exported from the United States or the country in
            which you reside) and (ii) your use of the Site will not infringe or
            misappropriate the intellectual property rights of any third party.
          </p>

          <p>
            Indemnification You agree to indemnify and hold harmless MLIFE, its
            contractors, and its licensors, and their respective directors,
            officers, employees and agents from and against any and all claims
            and expenses, including attorneys&rsquo; fees, arising out of your
            use of our Site, including but not limited to your violation of this
            Agreement.
          </p>

          <p>
            Disputes You agree to settle any claim or dispute arising from or
            related to this agreement through conciliation, and if necessary,
            legally binding arbitration in accordance with the Rules of
            Procedure for Christian Conciliation of the Institute for Christian
            Conciliation, a division of Peacemaker&reg; Ministries (complete
            text of the Rules is available here). Judgment upon an arbitration
            decision may be entered in any court otherwise having jurisdiction.
            You understand that these methods shall be the sole remedy for any
            controversy or claim arising out of this agreement and expressly
            waive your right to file a lawsuit in any civil court against one
            another for such disputes, except to enforce an arbitration
            decision. The proper venue for any disputes arising out of or
            relating to any of the same will be the state and federal courts
            located in San Francisco County, California unless otherwise
            provided by Law.
          </p>

          <p>
            California Residents If any complaint with us is not satisfactorily
            resolved, you can contact the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of
            Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112,
            Sacramento, California 95834 or by telephone at (800) 952-5210 or
            (916) 445-1254.
          </p>

          <p>
            Miscellaneous This Agreement constitutes the entire agreement
            between MLIFE and you concerning the subject matter hereof, and they
            may only be modified by a written amendment signed by an authorized
            executive of MLIFE, or by the posting by MLIFE of a revised version.
            Except to the extent applicable law, if any, provides otherwise,
            this Agreement, any access to or use of the Site will be governed by
            the laws of the state of California. If any clause or provision of
            this Agreement is found during the court of Arbitration or by a
            court of competent jurisdiction to be illegal, invalid, or
            unenforceable under present or future laws effective during the term
            of this Agreement, the remainder of this Agreement shall not be
            affected thereby, and you agree that in lieu of each clause or
            provision that is illegal, invalid or unenforceable, there be added
            as a part of this Agreement a clause or provision as similar in
            terms to such illegal, invalid or unenforceable clause or provision
            as may be possible and be legal, valid and enforceable.
          </p>

          <p>
            Termination MLIFE may terminate your access to all or any part of
            the Site at any time, with or without cause, with or without notice,
            effective immediately. If you wish to terminate this Agreement or
            your Mlifefoundation.org account (if you have one), you may simply
            discontinue using the Site. MLIFE Foundation can terminate the Site
            immediately as part of a general shut down of our service. All
            provisions of this Agreement which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>

          <p>
            Contact You are welcomed to contact us at: MLIFE Foundation 1909
            Mission Street, Suite 105 San Francisco, CA 94103
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@mlifefoundation.org"
            >
              {" "}
              hello@mlifefoundation.org
            </a>
            .
          </p>
        </div>
      </StyledTermsOfUse>
    </>
  )
}

export default TermsOfUse
